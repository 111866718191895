import React from "react";
import { Navigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";

const PublicRoutes = ({
  redirectPath = isMobile ? "/sportsbook/Cricket" : "/sports",
  children,
}) => {
  const { isAuth } = useSelector((state) => state.auth);
  if (isAuth) {
    return <Navigate to={redirectPath} replace />;
  }
  return children;
};
export default PublicRoutes;
